@use "vendor/bootstrap" as bootstrap;

body, html {
  font-size: 16px;
  font-family: 'Roboto', sans-serif !important;

  .text-danger {
    color: bootstrap.$danger !important;
  }

  .cut-long-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .ag-row-even, .ag-row-odd {
    max-height: 130px !important;
  }

  .weight-800 {
    font-weight: 800;
  }

  .weight-600 {
    font-weight: 600;
  }
}
