.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-footer {
  flex-wrap: nowrap;
}