@use "variable/color";
@use "variable/breakpoint";

.page {
  header {
    // Position
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    .header-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      gap: 1rem;
    }

    margin-bottom: 2rem;

    .actions {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      width: 100%;

      button {
        display: flex;
        justify-content: center;
        min-width: 130px;
      }
    }

    @media(min-width: breakpoint.$sm) {
      .header-row {
        align-items: center;
        flex-direction: row;
      }

      .actions {
        flex-direction: row;
        width: auto;
      }
    }

    // Look
    h1 {
      font-size: 1.5rem;
      font-weight: 700;

      color: color.$black;
    }
  }
}
