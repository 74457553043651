@use "variable/color";
@use "node_modules/ag-grid-community/styles" as ag;

@include ag.grid-styles((
  theme: alpine,
  --ag-background-color: white,
  --ag-data-color: color.$black,
  --ag-foreground-color: color.$black,
  --ag-header-background-color: color.$white,
  --ag-odd-row-background-color: white,
  --ag-borders: none,
  --ag-selected-row-background-color: color.$primary-900,
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem rgba(color.$primary, .3),
  --ag-font-size: 14px,
  --ag-font-family: Roboto,
  --ag-icon-font-family: agGridBalham,
  // Checkbox
  --ag-checkbox-checked-color: color.$primary-500,
  --ag-checkbox-unchecked-color: color.$gray-500,
  --ag-checkbox-indeterminate-color: color.$gray-500,
  --ag-icon-size: 18px
));

//.ag-row-even, .ag-row-odd {
//  display: flex;
//  align-items: center;
//}

.ag-center-cols-container {
  background-color: color.$page-background;
}

.ag-header-row {
  margin-bottom: 16px;
}

.ag-row-odd, .ag-row-even {
  border-radius: 16px !important;
  padding: 8px;
}