@use "variable/color";

ag-grid-angular {

  .ag-header {
    font-size: 13px;
  }

  .ag-checkbox {
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .ag-cell-wrapper {
    height: 100%;
  }

  .ag-cell {
    line-height: calc(var(--ag-internal-calculated-line-height) - 14px);
    height: auto !important;
    line-height: 28px !important;
  }

  .ag-row {
    border: 1px solid color.$primary-800;
    border-radius: 4px;
    overflow: hidden;
  }
}

.ag-theme-alpine .ag-overlay-no-rows-wrapper.ag-layout-auto-height, .ag-theme-alpine-dark .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  align-items: inherit;
  padding-top: 80px;
}

// Mobile view

//ag-grid-angular {
//  --ag-selected-row-background-color: inherit;
//  --ag-row-hover-color: black;
//
//  .ag-header {
//    display: none;
//  }
//
//
//  .ag-root {
//    .ag-body-viewport {
//      .ag-center-cols-viewport {
//        height: auto !important;
//      }
//
//      .ag-center-cols-clipper {
//        height: auto !important;
//      }
//
//      .ag-center-cols-viewport {
//        .ag-center-cols-container {
//          width: auto !important;
//          height: auto !important;
//
//          .ag-row {
//            display: flex !important;
//            height: auto !important;
//            flex-wrap: wrap;
//            max-height: unset !important;
//
//            .ag-cell {
//              position: static;
//            }
//
//            //position: static !important;
//            //display: flex;
//            //flex-wrap: wrap;
//            //row-gap: 0.75rem;
//            //height: auto !important;
//            //max-height: unset !important;
//            //padding-block: 0.5rem;
//            //transform: unset !important;
//            //border: 0;
//
//            //&:not(:first-child) {
//            //  margin-top: .5rem;
//            //}
//            //
//            //&:not(:last-child) {
//            //  border-bottom: 1px solid #D8E3E4;
//            //  border-radius: 0;
//            //}
//            //
//            //.ag-cell {
//            //  position: static !important;
//            //  width: 50% !important;
//            //  min-height: unset;
//            //  line-height: 1.2;
//            //  white-space: nowrap;
//            //  overflow: hidden !important;
//            //  text-overflow: ellipsis;
//            //
//            //  &.cell-full-width {
//            //    width: 100% !important;
//            //  }
//            //
//            //  &:not(.cell-hide-header)::before {
//            //    display: block;
//            //    padding-bottom: .5rem;
//            //    content: attr(data-header);
//            //
//            //    white-space: nowrap;
//            //    overflow: hidden;
//            //    text-overflow: ellipsis;
//            //
//            //    font-weight: 600;
//            //    color: color.$black;
//            //  }
//            //}
//          }
//        }
//      }
//    }
//
//    .ag-overlay {
//      .ag-overlay-no-rows-wrapper {
//        padding-top: 1rem !important;
//      }
//    }
//  }
//
//}

