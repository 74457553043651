@use "variable/color";

accordion-group {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid color.$gray-700;

  &:first-child {
    border-top: 2px solid color.$gray-700;
  }

  .accordion-toggle {
    button {
      color: color.$gray-200;
      font-weight: 500;
      font-size: 20px;
      padding-left: 0;
    }
  }

  .text-medium {
    font-size: 18px;
  }

  .text-small {
    font-size: 16px;
  }

  img {
    width: 90px;
    height: 81px;
  }

  .accordion-row {
    border-top: 1px dotted color.$gray-700;
    padding-top: 24px;
  }

  .accordion-heading {
    font-weight: 500;
    font-size: 20px;
  }

  .column-header {
    font-weight: 600;
  }

  .accordion-action-button {
    cursor: pointer;
  }

  .accordion-action-button.disabled {
    stroke: color.$gray-100;
    opacity: .3;
    cursor: auto;
    pointer-events: none;
  }

  .accordion-action-button.delete-icon {
    stroke: color.$red-200;
  }
}