@import "palette";

// Colors
$primary: $primary-300;
$secondary: $secondary-500;
$grey: $gray-500;
$red: $red-300;
$green: $green-500;

$black: #082042;
$white: #FFF;
$orange: #FFC100;

$success: $green;
$danger: $red;
