@use "variable/color";

tabset.tab-container {
  ul {
    li.nav-item {
      a.nav-link {
        border: none;
        font-weight: 600;
        color: color.$gray-500;

        &.active {
          color: color.$secondary-500;
          border-bottom: 3px solid color.$secondary-500;
        }

        &:focus-visible {
          color: color.$secondary-500;
          box-shadow: none;
        }
      }
    }
  }
}
